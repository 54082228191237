@import "~bootstrap/scss/bootstrap";
@import "feather-icon/feather-icon";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "theme/variables";
@import "theme/admin_style";
@import "theme/rtl";
@import "theme/chartist.scss";
@import "theme/datatable";
@import "icons/icoicon/icons";
// Flag contents
@import "flag-icon/flag-icon-base";
@import "flag-icon/flag-icon-more";
@import "flag-icon/flag-icon-list";
@import "icons/themify/themify-icons";
@import "~dropzone/dist/min/dropzone.min.css";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

.cdk-overlay-container {
  z-index: 999;
  position: absolute;
  width: 50%;
  left: 25%;
  bottom: 0;
  .success-snackbar {
    background-color: #5e8700;
    color: white;
    font-weight: bold;
    margin: 20px auto;
  }
  .error-snackbar {
    background-color: #dc3545;
    color: white;
    font-weight: bold;
    margin: 20px auto;
  }
}
